/* global default pages */

.GLOBGenpageCont {
    padding-top: 4%;

    & .GLOBGenpageRow {

        & .GLOBGenpageCol {

            & .GLOBGenpageTitle {

            }

            & .GLOBGenpageSubtitle {
                padding-top: 2%;
            }

            & .as-copy {
                margin-top: 4%;

                & .GLOBGenpagesection-wrapper {
                    padding-top: 2%;
                    padding-bottom: 2%;
                    & p {

                        & strong {

                        }
                    }

                    & ul {

                        & li {
                            font-size: 1.8rem;
                            line-height: 1.3em;
                        }
                    }
                }
            }
        }
    }
}