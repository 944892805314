/* mixins.scss */

@mixin clearfix {
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin tint {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

@mixin padding {
    padding-top: 30px;
    padding-bottom: 30px;
}

@mixin largeBullets {

    &:before{ 
        content:'\00b7'; font-size:100px; 
        vertical-align: middle;
        margin-right: 10px;
    }
    
}