@media only screen and (max-width: 1200px) and (min-width: 1024px) {

/* Navigation */

#navbar.navbar {
    background-color: white; 
    padding-top: 10px;
    padding-bottom: 10px;
    
    &.add-shadow {
        -webkit-box-shadow: 0 4px 6px -6px #999;
        -moz-box-shadow: 0 4px 6px -6px #999;
        box-shadow: 0 4px 6px -6px #999;
    }

    & .navbar-brand {
        padding: 5px;

        & img {
            height: 50px;
        }
    }

    & .navbar-toggler {

    }

    & .navbar-collapse {

        & .navbar-nav {

            & .nav-item {
                display: flex;
                align-items:flex-end;
                margin-right: 10px;
                margin-left: 10px;
                border-bottom: solid $border-px-size transparent;

                &.nav-underline-jquery {
                    border-bottom: solid $border-px-size $primary-color;
                }
                

                &.dropdown {
                    min-width: auto;

                    & .link-wrapper {
                        width: 100%;
                        display: inline-block;

                        & .dropdown-toggle[aria-expanded="true"]:after {
                            transform: rotate(180deg);
                            }
                            
                            
                            /*for the animation*/
                        & .dropdown-toggle:after {
                            transition: 0.2s;
                            }

                        & ul.dropdown-menu {
                            padding: 0;
                            margin-top: 6px;
                            min-width: 150px;
                            border: 0px;

                            & li {
                                

                                & a {
                                    padding: 5px 15px;
                                    display: inline-block;
                                }

                                &:hover {
                                    background-color: $primary-color;

                                    & a {
                                        color: white;
                                    }
                                }

                                &.active {
                                    background-color: $primary-color;

                                    & a {
                                        color: white;
                                    }
                                }


                            }
                        }
                    }
                }
                
                & a {
                    font-size: 1.6rem;
                    align-items: bottom;
                    color: black;
                    padding-left: 0;
                    padding-right: 0;
                    display: inline-block;
                }

                &.active {
                    border-bottom: solid $border-px-size $primary-color;
                }

                &:hover {
                    border-bottom: solid $border-px-size $primary-color;
                }
            }
        }
    }
}

h1 {
    font-size: 3rem;
}

h1.AB-Hero-Quote-h1 {
    font-size: 3rem;
    font-weight: 700;
}

h1.AB-Hero-Quote-h1 small {
    font-size: 1.6rem;
    font-weight: 400;
}

.as-copy h2 {
    font-size: 2.5rem;
    font-weight: 700;
}

h2.title-text {
    font-size: 2.8rem;
    font-weight: 700;
}

h1.hero-title {
    font-size: 2.8rem;
    font-weight: 700;
}

.HP1Cont .HP1Row1 .HP1Col1 .HP-hero-wrapper .image-wrapper .HP-hero-image {
    object-fit: cover;
    height: 100%;
}

.HP1Cont .HP1Row1 .HP1Col1 .HP-hero-wrapper .HP-strapline {
    position: absolute;
    bottom: 2%;
    left: 2%;
    border-bottom: solid 10px #FF3D01;
}

.HFD2Cont .HFD2Row1 .HFD2Col1-1 .as-copy p {
    font-size: 2.3rem;
}

.as-copy ul li {
    font-size: 1.8rem;
}

.GLOB7Cont .GLOB7Row1 .GLOB7Col1 .as-copy .title-text {
    font-size: 2.8rem;
}

.GLOB5Cont .GLOB5Row2 .GLOB5Col2 ul li {
    font-size: 1.8rem;
}


}