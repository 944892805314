@media only screen and (max-height: 550px) and (min-width: 551px) {

    .as-copy h2 {
        font-size: 2rem;
    }

    .GLOB7Cont .GLOB7Row1 .GLOB7Col1 .as-copy .title-text {
        font-size: 2.7rem;
    }

    .HFD2Cont .HFD2Row1 {
        margin-top: 0;
    }

    .HFD2Cont .HFD2Row1 .HFD2Col1-1 .as-copy p {
        font-size: 2.1rem;
    }

}