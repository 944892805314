/* single-product.scss */

/*
.PR1Cont {
    
    & .PR1Row1 {

        & .PR1Col1 {

        }
    }
}
*/

.PR1Cont {
    @include padding;
    padding-bottom: 1%;
    background-color: $third-gray;
    
    & .PR1Row1 {

        & .PR1Col1 {

            & .product-title-wrapper {

                & .product-75 {

                }

                & .product-title {

                }

                & .product-55 {
                    font-size: 1.6rem;
                }
            }
        }
    }
}

.PR2Cont {
    background-color: $third-gray;
    
    & .PR2Row1 {

        & .PR2Col1 {

            & .image-outer-wrapper {

                & .image-wrapper {
                    padding-bottom: 4%;
                    position: relative;

                    & .product-image {

                    }

                    & .image-caption {
                        position: absolute;
                        top: 30px;
                        left: 40px;
                        width: 100%;
                        max-width: 172px;
                        font-size: 1.6rem;

                        &.image-2585 {
                            background-color: white;
                            padding: 10px;
                        }

                        &.image-5989, &.image-5928, &.image5965 {
                            max-width: 100%;
                        }

                        &.image-3261 {
                            max-width: 228px;
                        }

                        &.image-2603 {
                            max-width: 234px;
                        }
                    }
                }
            }
        }

        & .PRADDCol1 {

            & .title-wrapper {
                padding-bottom: 2%;

                & h2.legacy-title {

                }

                & h3.legacy-sub-title {
                    font-size: 2.3rem;
                }

            }
        }
    }
}

.PR3Cont {
    @include padding;
    
    & .PR3Row1 {

        & .PR3Col1 {

            & .as-copy {

                & .product-notes-mini-title {
                    font-weight: 700;
                    font-size: 2rem;
                }

                & ul {

                    & li {

                    }
                }
            }
        }
    }
}