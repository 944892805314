/*typography.scss*/

body {
    color: $primary-font-color;
    font-family: 'Roboto', sans-serif;
}

h1, h2, h3, h4, h5 ,h6, p {
    line-height: 1.2em;
}

h1 {
    font-size: 3.5rem;
    font-weight: 700;

    &.hero-title {
        font-size: 3.5rem;
        font-weight: 700;
    }

    &.AB-Hero-Quote-h1 {
        font-size: 4rem;
        font-weight: 700;

        & small {
            font-size: 2rem;
            font-weight: 400;
        }
    }
    
}

h2 {
    font-size: 3.6rem;
    font-weight: 700;

    &.title-text {
        font-size: 4rem;
        font-weight: 700;
    }

    
    
}

h3 {

    &.sub-title-text {
        font-size: 1.8rem;
    }

    &.service-title-quote {
        font-size: 3.2rem;
    }
    
}

h4 {
    
}

p {
    font-size: 2rem;

    
}

li {
    
}

a, a:visited {
    color: black;
}

a:hover, a:active, a:focus {
    text-decoration: none;
    color: $primary-color;
}

.as-copy {

    & h2 {
        font-size: 3rem;

        &.red-text {
            font-size: 3rem;
            font-weight: 700;
        }

        &.standard-h2 {
            padding-top: 2%;
            padding-bottom: 1%;
        }
    }

    & p {
        font-size: 1.8rem;
        line-height: 1.3em;

        &.faded-text {
            font-size: 1.8rem;
        }
        
    }

    & ul {
        margin-left: 2%;

        & li {
            font-size: 2rem;
        }
    }

    & .caption-text {
        font-size: 1.5rem;        
    }
}



