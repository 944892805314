.button-wrapper {

    & .button-custom {
        border: solid 1px $primary-color;
        background-color: $primary-color;
        padding: 10px 20px;
        border-radius: 30px;
        color: white;
        font-size: 1.6rem;
    }
}
