/*Base.scss*/

*, 
*::after,
*::before {
    margin: 0;
    padding: 0;
}

html {
    //this defines what 1rem is
    font-size: 62.5%;
}

body {
    
}

.as-image {
    width: 100%;
}

.contained-row {
    max-width: 1200px;
    margin: 0 auto;
}



