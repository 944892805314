/* Top-nav.scss */

.subnav {
    border-bottom: solid 1px $secondary-color;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 30px;
    padding-right: 30px;

    & .navbar-collapse {

        & ul.navbar-nav {

            & .nav-item {
                display: flex;
                align-items: center;
                margin-right: 10px;
                margin-left: 10px;

                & a {
                    color: $primary-color;
                    font-size: 1.4rem;

                    & .nav-icon {
                        
                    }
                }
            }
        }
    }
}