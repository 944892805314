/* HFD.scss */

/*
.HFD1Cont {
    
    & .HFD1Row1 {

        & .HFD1Col1 {

        }
    }
}
*/

.HFD1Cont {
    @include padding;
    padding-bottom: 0;
    
    & .HFD1Row1 {

        & .HFD1Col1 {

            & .title-wrapper {

                & .service-title {

                    & .title-span {
                        border-bottom: solid $border-px-size $primary-color;

                    }
                }
            }
        }
    }
}

.HFD2Cont {
    @include padding;
    padding-top: 0;

    & .HFD2Row1 {
        display: flex;
        align-items: center;
        margin-top: 60px;

        & .HFD2Col1-1 {

            & .as-copy {
                

                & p {
                    font-size: 2.8rem;
                }
            }
        }

        & .HFD2Col1-2 {

            & .image-wrapper {
                text-align: center;

                & .as-logo {
                    
                }
            }
        }
    }
}

.HFD3Cont {
    @include padding;
    padding-top: 0;
    
    & .HFD3Row1 {

        & .HFD3Col1 {

            & .quote-wrapper {

                & .title-text {

                }
            }
        }
    }
}

.HFD4Cont {
    @include padding;
    padding-top: 0;
    
    & .HFD4Row1 {

        & .HFD4Col1 {

            & .as-copy {

                & .HFD-smaller-title {
                    font-size: 2.7rem;
                    padding-bottom: 2%;
                }

                & p {

                }
            }
        }
    }
}

.HFD5Cont {
    @include padding;
    padding-top: 0;
    
    & .HFD5Row1 {

        & .HFD5Col1 {

            & .as-copy {

                & .image-title {
                    padding-bottom: 4%;
                }
            }
        }
    }

    & .HFD5Row2 {

        & .HFD5Col2 {
            padding: 5px;
            padding-top: 0;

            & .image-wrapper {
                height: 100%;

                & .FE-Photo {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    & .HFD5Row3 {

        & .HFD5Col3 {

            & .as-copy {

                & .caption-text {
                    margin-top: 15px;
                }
            }
        }
    }
}

.HFD6Cont {
    
    & .HFD6Row1 {

        & .HFD6Col1 {

            & .as-copy {

                & h2 {
                    padding-top: 4%;
                    padding-bottom: 2%;
                }

                & ul {

                    & li {

                    }
                }
            }
        }
    }
}