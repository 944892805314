@media only screen and (max-width: 1023px) and (min-width: 601px) {

        /*typography.scss*/
        
        body {
            color: $primary-font-color;
            font-family: 'Roboto', sans-serif;
        }
        
        h1, h2, h3, h4, h5 ,h6, p {
            line-height: 1.2em;
        }
        
        h1 {
            font-size: 2.7rem;
            font-weight: 700;
        
            &.hero-title {
                font-size: 2.7rrem;
                font-weight: 700;
            }
        
            &.AB-Hero-Quote-h1 {
                font-size: 2.7rem;
                font-weight: 700;
        
                & small {
                    padding-top: 15px;
                    padding-bottom: 5px;
                    font-size: 1.6rem;
                    font-weight: 400;
                    line-height: 1.2em;
                    display: block;
                }
            }
            
        }
        
        h2 {
            font-size: 2.8rem;
            font-weight: 700;
        
            &.title-text {
                font-size: 2.8rem;
                font-weight: 700;
            }
        
            
            
        }
        
        h3 {
        
            &.sub-title-text {
                font-size: 1.6rem;
            }
        
            &.service-title-quote {
                font-size: 2.4rem;
            }
            
        }
        
        h4 {
            
        }
        
        p {
            font-size: 1.6rem;
        
            
        }
        
        li {
            
        }
        
        a, a:visited {
            color: black;
        }
        
        a:hover, a:active, a:focus {
            text-decoration: none;
            color: $primary-color;
        }
        
        .as-copy {
        
            & h2 {
                font-size: 2.2rem;
        
                &.red-text {
                    font-size: 2.2rem;
                    font-weight: 700;
                }
        
                &.standard-h2 {
                    padding-top: 2%;
                    padding-bottom: 1%;
                }
            }
        
            & p {
                font-size: 1.6rem;
                line-height: 1.2em;
        
                &.faded-text {
                    font-size: 1.6rem;
                }
                
            }
        
            & ul {
                margin-left: 2%;
        
                & li {
                    font-size: 1.6rem;
                }
            }
        
            & .caption-text {
                font-size: 1.5rem;        
            }
        }
        
        
        
        
        
        
        
        /* Top-nav.scss */
        
        .subnav {
            display: none;
        
            & .navbar-collapse {
        
                & ul.navbar-nav {
        
                    & .nav-item {
        
                        & a {
        
                            & .nav-icon {
                                
                            }
                        }
                    }
                }
            }
        }
        
        /* Navigation */
        
        #navbar.navbar {
            background-color: white; 
            padding-top: 10px;
            padding-bottom: 10px;
            
            &.add-shadow {
                -webkit-box-shadow: 0 4px 6px -6px #999;
                -moz-box-shadow: 0 4px 6px -6px #999;
                box-shadow: 0 4px 6px -6px #999;
            }
        
            & .navbar-brand {
                padding: 5px;
        
                & img {
                    height: 60px;
                }
            }
        
            & .navbar-toggler {
        
            }
        
            & .navbar-collapse {
        
                & .navbar-nav {
                    margin-top: 4%;
        
                    & .nav-item {
                        display: flex;
                        align-items:flex-end;
                        margin-right: 10px;
                        margin-left: 10px;
                        border-bottom: solid $border-px-size transparent;
        
                        &.nav-underline-jquery {
                            border-bottom: solid $border-px-size $primary-color;
                        }
                        
        
                        &.dropdown {
                            min-width: 150px;
        
                            & .link-wrapper {
                                width: 100%;
                                display: inline-block;
        
                                & .dropdown-toggle[aria-expanded="true"]:after {
                                    transform: rotate(180deg);
                                    }
                                    
                                    
                                    /*for the animation*/
                                & .dropdown-toggle:after {
                                    transition: 0.2s;
                                    }
        
                                & ul.dropdown-menu {
                                    padding: 0;
                                    margin-top: 6px;
                                    min-width: 150px;
                                    border: 0px;
        
                                    & li {
                                        
        
                                        & a {
                                            padding: 5px 15px;
                                            display: inline-block;
                                        }
        
                                        &:hover {
                                            background-color: $primary-color;
        
                                            & a {
                                                color: white;
                                            }
                                        }
        
                                        &.active {
                                            background-color: $primary-color;
        
                                            & a {
                                                color: white;
                                            }
                                        }
        
        
                                    }
                                }
                            }
                        }
                        
                        & a {
                            font-size: 1.6rem;
                            align-items: bottom;
                            color: black;
                            padding-left: 0;
                            padding-right: 0;
                            display: inline-block;
                        }
        
                        &.active {
                            border-bottom: solid $border-px-size $primary-color;
                        }
        
                        &:hover {
                            border-bottom: solid $border-px-size $primary-color;
                        }
                    }
                }
            }
        }
        
        /* home.scss */
        
        .HP1Cont {
            @include padding;
            
            & .HP1Row1 {
        
                & .HP1Col1 {
                    height: auto;
                    padding-left: 0;
                    padding-right: 0;
        
                    & .HP-hero-wrapper {
        
                        & .image-wrapper {
        
                            & .HP-hero-image {
                            }
                        }
        
                        .HP-strapline {
                            bottom: 0%;
                            left: 0%;
                            border-bottom: solid 10px $primary-color;
                            padding-left: 4%;
        
                            & .hero-title {
        
                            }
                        }
                        
                    }
                }
            }
        }
        
        .HP2Cont {
            @include padding;
            
            & .HP2Row1 {
        
                & .HP2Col1 {
        
                    & .as-copy {
                        text-align: center;
        
                        & p {
                            font-size: 1.8rem;
        
                            & span {
        
                                & img {
                                    height: 3rem;
                                }
                            }
                        }
                    }
        
                }
            }
        }
        
        .HP3Cont {
            @include padding;
            
            & .HP3Row1 {
        
                & .HP3Col1 {
        
                    &.HP3Col1-1 {
                        padding-bottom: 30px;
                    }
                    
        
                    & .as-copy {
                        max-width: 100%;
        
                        & .title-wrapper {
                            min-height: auto;
        
                            & .title-text {
        
                            }
            
                            & .sub-title-text {
                                font-weight: 700;
                            }
        
                        }
        
                        & .text-wrapper {
                            padding-top: 4%;
        
                            & p {
                                margin-bottom: 20px;
                            }
                        }
                    }
                }
            }
        }
        
        .HP4Cont {
            @include padding;
            
            & .HP4Row1 {
        
                & .HP4Col1 {
        
                    & .as-copy {
                        text-align: center;
        
                        & p {
        
                        }
        
                        & .flag-wrapper {
                            display: flex;
                            justify-content: center;
        
                            & .flag {
                                margin-right: 2%;
                            }
                        }
                    }
        
                }
            }
        }
        
        /* single-product.scss */
        
        /*
        .PR1Cont {
            
            & .PR1Row1 {
        
                & .PR1Col1 {
        
                }
            }
        }
        */
        
        .PR1Cont {
            @include padding;
            background-color: $third-gray;
            
            & .PR1Row1 {
        
                & .PR1Col1 {
        
                    & .product-title-wrapper {
        
                        & .product-75 {
        
                        }
        
                        & .product-title {
        
                        }
        
                        & .product-55 {
                            font-size: 1.6rem;
                        }
                    }
                }
            }
        }
        
        .PR2Cont {
            background-color: $third-gray;
            
            & .PR2Row1 {
        
                & .PR2Col1 {
        
                    & .image-outer-wrapper {
        
                        & .image-wrapper {
                            padding-bottom: 30px;
                            position: relative;
        
                            & .product-image {
        
                            }
        
                            & .image-caption {
                                position: static;
                                top: 30px;
                                left: 40px;
                                width: 100%;
                                max-width: 100%;
                                font-size: 1.6rem;
                                padding-top: 4%;
                            }
                        }
                    }
                }
        
                & .PRADDCol1 {
        
                    & .title-wrapper {
                        padding-bottom: 30px;
        
                        & h2.legacy-title {
        
                        }
        
                        & h3.legacy-sub-title {
                            font-size: 1.8rem;
                        }
        
                    }
                }
            }
        }
        
        .PR3Cont {
            @include padding;
            
            & .PR3Row1 {
        
                & .PR3Col1 {
        
                    & .as-copy {
        
                        & .product-notes-mini-title {
                            font-weight: 700;
                            font-size: 2rem;
                        }
        
                        & ul {
        
                            & li {
        
                            }
                        }
                    }
                }
            }
        }
        
        /* HFD.scss */
        
        /*
        .HFD1Cont {
            
            & .HFD1Row1 {
        
                & .HFD1Col1 {
        
                }
            }
        }
        */
        
        .HFD1Cont {
            @include padding;
            
            & .HFD1Row1 {
        
                & .HFD1Col1 {
        
                    & .title-wrapper {
        
                        & .service-title {
                            line-height: 1.6em;
        
                            & .title-span {
                                border-bottom: solid $border-px-size $primary-color;
        
                            }
                        }
                    }
                }
            }
        }
        
        .HFD2Cont {
            @include padding;
            padding-top: 0;
        
            & .HFD2Row1 {
                display: flex;
                align-items: center;
                margin-top: 10px;
        
                & .HFD2Col1-1 {
        
                    & .as-copy {
        
                        & p {
                            font-size: 2.3rem;
                        }
                    }
                }
        
                & .HFD2Col1-2 {
        
                    & .image-wrapper {
                        text-align: center;
        
                        & .as-logo {
        
                        }
                    }
                }
            }
        }
        
        .HFD3Cont {
            @include padding;
            
            & .HFD3Row1 {
        
                & .HFD3Col1 {
        
                    & .quote-wrapper {
        
                        & .title-text {
        
                        }
                    }
                }
            }
        }
        
        .HFD4Cont {
            @include padding;
            
            & .HFD4Row1 {
        
                & .HFD4Col1 {
        
                    & .as-copy {
        
                        & .HFD-smaller-title {
                            font-size: 2.7rem;
                            padding-bottom: 2%;
                        }
        
                        & p {
        
                        }
                    }
                }
            }
        }
        
        .HFD5Cont {
            @include padding;
            padding-top: 0;
            
            & .HFD5Row1 {
        
                & .HFD5Col1 {
        
                    & .as-copy {
        
                        & .image-title {
                            padding-bottom: 4%;
                        }
                    }
                }
            }
        
            & .HFD5Row2 {
        
                & .HFD5Col2 {
                    padding: 5px;
                    padding-top: 0;
        
                    & .image-wrapper {
                        height: 100%;
        
                        & .FE-Photo {
                            object-fit: cover;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        
            & .HFDRow3 {
        
                & .HFD5Col3 {
        
                    & .as-copy {
        
                        & .caption-text {
        
                        }
                    }
                }
            }
        }
        
        .HFD6Cont {
            
            & .HFD6Row1 {
        
                & .HFD6Col1 {
        
                    & .as-copy {
        
                        & h2 {
                            padding-top: 4%;
                            padding-bottom: 2%;
                        }
        
                        & ul {
        
                            & li {
        
                            }
                        }
                    }
                }
            }
        }
        
        /* services.scss */
        
        /*
        .SE1Cont {
            
            & .SE1Row1 {
        
                & .SE1Col1 {
        
                }
            }
        }
        */
        
        .SE1Cont {
            @include padding;
            
            & .SE1Row1 {
        
                & .SE1Col1 {
        
                    & .title-wrapper {
        
                        & .title-span {
                            display: inline-block;
                            border-bottom: solid $border-px-size $primary-color;
        
                            & .service-title {
        
                            }
        
                            & .service-title-quote {
                                
                            }
                        }
                    }
                }
            }
        }
        
        .SE2Cont {
            @include padding;
            
            & .SE2Row1 {
        
                & .SE2Col1 {
        
                    & .image-wrapper {
                        text-align: center;
                        max-width: 600px;
                        width: 70%;
                        margin: 0 auto;
        
                        & .services-top-circle {
        
                        }
        
                    }
        
                }
            }
        }
        
        .SE3Cont {
            @include padding;
            
            & .SE3Row1 {
        
                & .SE3Col1 {
        
                    & .as-copy {
        
                    }
        
                }
            }
        }
        
        .SE4Cont {
            
            & .SE4Row1 {
                @include padding;
                padding-top: 0;
        
                & .SE4Col1 {
        
                    & .title-wrapper {
        
                        & h2 {
                            font-size: 2.8rem;
                            line-height: 1.3em;
        
                            & strong {
                                font-size: 3rem;
                            }
                        }
                    }
        
                    & .image-wrapper {
                        @include padding;
                        width: 100%;
                        max-width: 1000px;
                        margin: 0 auto;
        
                        & img.as-image {
        
                        }
                    }
        
                    & .as-copy {
        
                        & ul {
        
                            & li {
        
                            }
                        }
                    }
                }
            }
        
            & .SE4Row3 {
                padding-bottom: 2%;
                max-width: 1000px;
                margin: 0 auto;
        
                & .SE3Col3-1 {
        
                }
        
                & .SE3Col3-2 {
        
                }
            }
        
            & .SE4Row4 {
                padding-bottom: 2%;
                max-width: 1000px;
                margin: 0 auto;
        
                & .SE4Col4-1 {
        
                }
        
                & .SE4Col4-2 {
        
                }
            }
        
            & .SE4Row5 {
        
                & .SE4Col5 {
        
                    & .as-copy {
                        
                        & .first-h2 {
                            padding-bottom: 2%;
                        }
        
                        & h2 {
                            padding-bottom: 1%;
                        }
        
                        & ul {
                            padding-bottom: 2%;
                        }
        
                        & p {
                            padding-bottom: 2%;
                        }
                    }
        
                }
            }
        }
        
        /* contact.scss */
        
        /*
        .CO1Cont {
            
            & .CO1Row1 {
        
                & .CO1Col1 {
        
                }
            }
        }
        */
        
        .CO1Cont {
            @include padding;
            
            & .CO1Row1 {
        
                & .CO1Col1 {
        
                    & .title-wrapper {
                        border-bottom: solid $border-px-size $primary-color;
        
                        & .service-title {
        
                        }
                    }
                }
            }
        }
        
        .CO2Cont {
            @include padding;
            
            & .CO2Row1 {
        
                & .CO2Col1-1 {
        
                    & .image-wrapper {
        
                        & .contact-image {
        
                        }
        
                    }
                }
        
                & .CO2Col1-2 {
                    padding-top: 30px;
        
                    & .as-copy {
        
                        & .content-wrapper {
        
                            & .icon-text-wrapper {
                                display: flex;
                                padding-bottom: 30px;
        
                                & .icon-wrapper {
                                    margin-right: 2%;
        
                                    & .as-icon {
                                        width: 30px;
                                        height: 30px;
                                    }
                                }
        
                                & .text-content {
        
                                    & p {
        
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        
        /* Global Footer Content */
        
        .GLOB5Cont {
            @include padding;
            
            & .GLOB5Row1 {
        
                & .GLOB5Col1 {
        
                    & .as-copy {
        
                        & .title-text {
                            text-align: center;
                            padding-bottom: 4%;
                        }
        
                    }
        
                }
            }
        
            & .GLOB5Row2 {
        
                & .GLOB5Col2 {
                    padding-bottom: 2%;
        
                    & .text-wrapper {
                        border: solid 1px $primary-color;
                        padding: 8%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 100%;
        
                        & p {
                            margin-bottom: 0;
                            text-align: center;
                        }
        
                    }
                    & ul {
                        height: 200px;
                    }
        
                }
        
            }
        }
        
        .GLOB6Cont {
            @include padding;
            
            & .GLOB6Row1 {
        
                & .GLOB6Col1 {
        
                    & .as-copy {
                        text-align: center;
        
                        & p.faded-text {
                            color: $secondary-color;
                        }
        
                        & h2.red-text {
                            color: $primary-color;
                        }
                    }
                }
            }
        }
        
        .GLOB7Cont {
            @include padding;
            
            & .GLOB7Row1 {
        
                & .GLOB7Col1 {
        
                    & .as-copy {
        
                        & .title-text {
                            text-align: center;
                            margin-bottom: 40px;
                            font-size: 3rem;
                        }
        
                        & .button-wrapper {
                            text-align: center;
        
                            & .button-custom {
        
                            }
        
                        }
                    }
                }
            }
        }
        
        .GLOB8Cont {
            @include padding;
            
            & .GLOB8Row1 {
        
                & .GLOB8Col1 {
        
                    & .logo-list-wrapper {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        align-items: bottom;
        
                        & .logo-wrapper {
                            padding-bottom: 20px;
        
                            & .logo {
        
                            }
                        }
                    }
        
                }
            }
        }
        
        .footer {
            padding-top: 2%;
            padding-bottom: 2%;
            background-color: $secondary-color;
        
            & .row.inner-footer-wrapper {
        
                & .footer-col {
                    padding-top: 20px;
        
                    & .as-copy {
        
                        & p {
                            font-size: 1.6rem;
                        }
                    }
        
                    &.footer-col-1 {
                        
        
                        & .logo-wrapper {
        
                            & .footer-logo {
                                width: 60%;
                            }
                        }
        
                        & .col-1-copy {
                            padding-top: 30px;
        
                            & p {
                                font-size: 1.5rem;
                            }
                        }
                    }
        
                    &.footer-col-2 {
                        
                        & .logo-wrapper {
                            display: flex;
                            align-items: center;
                            height: 100%;
                            justify-content: flex-start;
        
                            & .footer-square-logo {
        
                            }
                        }
                    }
        
                    &.footer-col-3 {
        
                        & .as-copy {
        
                            & p {
        
                                & a {
        
                                }
                            }
                        }
                    }
        
                    &.footer-col-4 {
        
                        & .as-copy {
        
                            & p {
        
                            }
                        }
                    }
        
                    &.footer-col-5 {
        
                        & .as-copy {
        
                            & p {
        
                            }
                        }
                    }
                }
        
            }
        }

}