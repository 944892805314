/* services.scss */

/*
.SE1Cont {
    
    & .SE1Row1 {

        & .SE1Col1 {

        }
    }
}
*/

.SE1Cont {
    @include padding;
    
    & .SE1Row1 {

        & .SE1Col1 {

            & .title-wrapper {

                & .title-span {
                    display: inline-block;
                    border-bottom: solid $border-px-size $primary-color;

                    & .service-title {

                    }

                    & .service-title-quote {
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }
}

.SE2Cont {
    
    & .SE2Row1 {

        & .SE2Col1 {

            & .image-wrapper {
                text-align: center;
                max-width: 600px;
                width: 100%;
                margin: 0 auto;

                & .services-top-circle {

                }

            }

        }
    }
}

.SE3Cont {
    @include padding;
    
    & .SE3Row1 {

        & .SE3Col1 {

            & .as-copy {

            }

        }
    }
}

.SE4Cont {
    
    & .SE4Row1 {
        @include padding;
        padding-top: 0;

        & .SE4Col1 {

            & .title-wrapper {

                & h2 {
                    font-size: 2.8rem;
                    line-height: 1.3em;
                    font-weight: 400;

                    & strong {
                        font-size: 3rem;
                    }
                }
            }

            & .image-wrapper {
                @include padding;
                width: 100%;
                max-width: 1000px;
                margin: 0 auto;

                & img.as-image {

                }
            }

            & .as-copy {

                & ul {

                    & li {

                        & .ref-wrapper {
                            color: $primary-color;
                        }

                    }
                }
            }
        }
    }

    & .SE4Row3 {
        padding-bottom: 2%;
        margin: 0 auto;

        & .SE4Col3 {

            & .as-copy {

                & p {
                    font-size: 2.5rem;
                }
            }
        }

        & .SE3Col3-1 {

        }

        & .SE3Col3-2 {

        }
    }

    & .SE4Row4 {
        padding-top: 4%;
        padding-bottom: 2%;
        margin: 0 auto;

        & .SE4Col4 {

            & .as-copy {

                & p {
                    font-size: 2.5rem;
                }
            }
        }

        & .SE4Col4-1 {

        }

        & .SE4Col4-2 {

            & .image-wrapper {
                padding-bottom: 4%;
            }

            & .desktop {
                display: block;
            }

            & .mobile {
                display: none;
            }

        }
    }

    & .SE4Row5 {

        & .SE4Col5 {

            & .as-copy {
                
                & .first-h2 {
                    padding-bottom: 2%;
                }

                & h2 {
                    padding-bottom: 1%;
                    font-weight: 400;
                }

                & ul {
                    padding-bottom: 2%;
                }

                & p {
                    padding-bottom: 2%;
                }
            }

        }

        & .quotation-col {

            & .quotation-text {
                margin-bottom: 0;
                padding-top: 30px;
                padding-bottom: 30px;
                text-align: center;
            }
        }
    }
}