/* about.scss */

/*
.AB1Cont {
    
    & .AB1Row1 {

        & .AB1Col1 {

        }
    }
}
*/

.AB1Cont {
    @include padding;
    
    & .AB1Row1 {

        & .AB1Col1 {

            & .commitment-wrapper {
                margin-bottom: 15px;

                & .commitmenth3 {
                    
                }
            }

            & .ABhero-quote-text-wrapper {
                border-bottom: solid $border-px-size $primary-color;

                & .AB-Hero-Quote-h1 {
                    margin-bottom: 4%;
                    max-width: 1100px;

                    & small {
                        margin-left: 0px;
                        font-size: 1.6rem;

                    }
                }

            }

        }
    }

    & .AB1Row2 {

        & .AB1Col2 {

            & .image-wrapper {
                @include padding;

                & img.AB-hero-image {

                }
            }
        }
    }
}