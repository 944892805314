/* home.scss */

/*
.HP1Cont {
    
    & .HP1Row1 {

        & .HP1Col1 {

        }
    }
}
*/

.HP1Cont {
    padding-bottom: 0px;
    padding-top: 10px;
    
    & .HP1Row1 {

        & .HP1Col1 {
            height: calc(100vh - 160px);

            & .HP-hero-wrapper {
                position: relative;
                height: 100%;

                & .image-wrapper {
                    height: 100%;

                    & .HP-hero-image {
                        object-fit: cover;
                        height: 100%;
                    }
                }

                .HP-strapline {
                    position: absolute;
                    bottom: 3%;
                    left: 2%;
                    border-bottom: solid 10px $primary-color;

                    & .hero-title {

                    }
                }
                
            }
        }
    }
}

.HP2Cont {
    @include padding;
    padding-bottom: 60px;
    
    & .HP2Row1 {

        & .HP2Col1 {

            & .as-copy {
                text-align: center;

                & p {
                    font-size: 1.8rem;

                    & span {

                        & img {
                            height: 3rem;
                        }
                    }

                    &.intro-para {
                        margin-bottom: 0;
                        line-height: 1.5em;
                    }

                    &.line-height-break {
                        margin-bottom: 0;
                        line-height: 1.5em;
                    }
                }
            }

        }
    }
}

.HP3Cont {
    @include padding;
    
    & .HP3Row1 {

        & .HP3Col1 {

            & .as-copy {
                max-width: 482px;

                & .title-wrapper {
                    min-height: 100px;

                    &.series-7-5 {
                        max-width: 300px;
                        width: 100%;
                    }

                    & .title-text {

                    }
    
                    & .sub-title-text {
                        font-weight: 700;
                    }

                }

                & .text-wrapper {
                    padding-top: 4%;

                    & p {
                        margin-bottom: 40px;
                    }
                }
            }
        }
    }
}

.HP4Cont {
    @include padding;
    
    & .HP4Row1 {

        & .HP4Col1 {

            & .as-copy {
                text-align: center;

                & p {
                    font-weight: 700;
                }

                & .flag-wrapper {
                    display: flex;
                    justify-content: center;

                    & .flag {
                        margin-right: 2%;
                    }
                }
            }

        }
    }
}

