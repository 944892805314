/* finishes.scss */

/*
.FI1Cont {
    
    & .FI1Row1 {

        & .FI1Col1 {

        }
    }
}
*/

.FI1Cont {
    @include padding;
    padding-bottom: 0;
    
    & .FI1Row1 {

        & .FI1Col1 {

            & .title-wrapper {
                display: flex;
                justify-content: space-between;

                & .finishes-wrapper {

                    & h1 {

                    }
                }

                & .GSA-logo-wrapper {

                    & .gsa-logo {
                        width: 200px;
                    }
                }
            }

        }
    }
}

.FI2Cont {
    @include padding;
    
    & .FI2Row1 {

        & .FI2Col1 {

            & .finish-section-title {
                font-weight: 700;
                padding-bottom: 2%;
            }
        }

        & .FI2Col2-1 {
            padding-bottom: 2%;

            & .image-wrapper {

                & .finish-square-image {

                }
            }

            & .finish-square-text {
                padding-top: 2%;
                font-size: 1.4rem;
            }
        }
    }
}