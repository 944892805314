/* Footer */

.GLOB5Cont {
    @include padding;
    
    & .GLOB5Row1 {

        & .GLOB5Col1 {

            & .as-copy {

                & .title-text {
                    text-align: center;
                    padding-bottom: 4%;
                }

            }

        }
    }

    & .GLOB5Row2 {

        & .GLOB5Col2 {
            padding-bottom: 0%;

            & ul {
                width: 100%;
                max-width: 800px;
                margin: 0 auto;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                height: 230px;
                list-style-type: none; 

                & li {
                    line-height: 2.5em;

                    @include largeBullets;
                }
            }

        }

    }
}

.GLOB6Cont {
    @include padding;
    
    & .GLOB6Row1 {

        & .GLOB6Col1 {

            & .as-copy {
                text-align: center;

                & p.faded-text {
                    color: $secondary-color;
                }

                & h2.red-text {
                    

                    & a {
                        color: $primary-color;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}

.GLOB7Cont {
    @include padding;
    
    & .GLOB7Row1 {

        & .GLOB7Col1 {

            & .as-copy {

                & .title-text {
                    text-align: center;
                    margin-bottom: 4%;
                    max-width: 765px;
                    margin: 0 auto;
                    font-size: 4rem;
                }

                & .button-wrapper {
                    text-align: center;
                    margin-top: 50px;

                    & .button-custom {

                    }

                }
            }
        }
    }
}

.GLOB8Cont {
    padding-top: 60px;
    padding-bottom: 60px;
    
    & .GLOB8Row1 {

        & .GLOB8Col1 {

            & .logo-list-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: bottom;

                & .logo-wrapper {

                    & .logo {

                    }
                }
            }

        }
    }
}

.footer {
    padding-top: 2%;
    padding-bottom: 2%;
    background-color: $secondary-color;

    & .row.inner-footer-wrapper {

        & .footer-col {

            & .as-copy {

                & p {
                    font-size: 1.6rem;
                }
            }

            &.footer-col-1 {

                & .logo-wrapper {

                    & .footer-logo {
                        
                        &:hover {
                            cursor: pointer;
                        }
                    }
                }

                & .col-1-copy {
                    padding-top: 30px;

                    & p {
                        font-size: 1.5rem;
                    }
                }
            }

            &.footer-col-2 {
                
                & .logo-wrapper {
                    display: flex;
                    align-items: center;
                    height: 100%;
                    justify-content: center;

                    & .footer-square-logo {

                    }
                }
            }

            &.footer-col-3 {

                & .as-copy {

                    & p {

                        & a {

                        }
                    }
                }
            }

            &.footer-col-4 {

                & .as-copy {

                    & p {

                    }
                }
            }

            &.footer-col-5 {

                & .as-copy {

                    & p {

                    }
                }
            }
        }

    }
}