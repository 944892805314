.wpcf7 {

    & .wpcf7-form {

        & p {

            & .wpcf7-form-control-wrap {

                & input, & textarea {
                    width: 100%;
                    border: solid 1px transparent;
                    border-bottom: solid 1px black;
                    background-color: transparent;
                    margin-bottom: 2%;
                    padding-left: 0;

                    &::placeholder {
                        font-weight: 400;
                        color: black;
                    }
                }
            }

            & input[type=submit] {
                background-color: white;
                color: black;
                font-size: 2rem;
                border: solid 1px black;
            }
        }
    }
}