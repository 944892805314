/* Navigation */

#navbar.navbar {
    background-color: white; 
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 25px;
    padding-right: 25px;
    
    &.add-shadow {
        -webkit-box-shadow: 0 4px 6px -6px #999;
        -moz-box-shadow: 0 4px 6px -6px #999;
        box-shadow: 0 4px 6px -6px #999;
    }

    & .navbar-brand {
        padding: 5px;

        & img {
            height: 80px;
        }
    }

    & .navbar-toggler {;
        border-color: rgba(0,0,0,0);

        & .navbar-toggler-icon {
            width: 1.7em;
            height: 1.7em;
        }
    }

    & .navbar-collapse {

        & .navbar-nav {

            & .nav-item {
                display: flex;
                align-items:flex-end;
                margin-right: 15px;
                margin-left: 15px;
                border-bottom: solid $border-px-size transparent;
                

                &.nav-underline-jquery {
                    border-bottom: solid $border-px-size $primary-color;
                }
                

                &.dropdown {
                    min-width: 125px;

                    & .link-wrapper {
                        width: 100%;
                        display: inline-block;

                        & .dropdown-toggle[aria-expanded="true"]:after {
                            transform: rotate(180deg);
                            }
                            
                            
                            /*for the animation*/
                        & .dropdown-toggle:after {
                            transition: 0.2s;
                            }

                        & ul.dropdown-menu {
                            padding: 0;
                            margin-top: 6px;
                            min-width: 125px;
                            border: 0px;

                            & li {
                                

                                & a {
                                    padding: 5px 10px;
                                    display: inline-block;
                                    font-size: 1.4rem;
                                }

                                &:hover {
                                    background-color: $primary-color;

                                    & a {
                                        color: white;
                                    }
                                }

                                &.active {
                                    background-color: $primary-color;

                                    & a {
                                        color: white;
                                    }
                                }


                            }
                        }
                    }
                }
                
                & a {
                    font-size: 1.6rem;
                    align-items: bottom;
                    color: black;
                    padding-left: 0;
                    padding-right: 0;
                    display: inline-block;
                    padding-bottom: 0;
                }

                &.active {
                    border-bottom: solid $border-px-size $primary-color;
                }

                &:hover {
                    border-bottom: solid $border-px-size $primary-color;
                }
            }
        }
    }
}
