/* contact.scss */

/*
.CO1Cont {
    
    & .CO1Row1 {

        & .CO1Col1 {

        }
    }
}
*/

.CO1Cont {
    @include padding;
    
    & .CO1Row1 {

        & .CO1Col1 {

            & .title-wrapper {
                border-bottom: solid $border-px-size $primary-color;

                & .service-title {

                }
            }
        }
    }
}

.CO2Cont {
    @include padding;
    padding-top: 0;
    padding-bottom: 100px;
    
    & .CO2Row1 {

        & .CO2Col1-1 {

            & .image-wrapper {

                & .contact-image {

                }

            }
        }

        & .CO2Col1-2 {

            & .as-copy {

                & .content-wrapper {

                    & .icon-text-wrapper {
                        display: flex;
                        padding-bottom: 30px;

                        & .icon-wrapper {
                            margin-right: 10px;

                            & .as-icon {
                                width: 30px;
                                height: 30px;
                            }
                        }

                        & .text-content {
                            display: flex;
                            justify-content: center;
                            flex-direction: column;

                            & p {
                                margin-bottom: 2px;

                                & a {
                                color: $primary-color;

                                    &:hover {
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }
                    }

                    & .directions-wrapper {

                        
                    }
                }
            }
        }
    }
}